// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.miniprojects-navbar {
    display:flex;
    border: 2px solid var(--dark-gray1);
}

.miniprojects-navbar a {
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px 5px 20px;
    text-align:center;
    width: 10%;
    text-decoration:none;
    color:black;
}

.miniprojects-navbar a:first-child{
    margin-right:auto;
}

.miniprojects-navbar a.active{
    background-color:var(--blue1);
}
`, "",{"version":3,"sources":["webpack://./src/layouts/MiniProjectsLayout.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mCAAmC;AACvC;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,0BAA0B;IAC1B,iBAAiB;IACjB,UAAU;IACV,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".miniprojects-navbar {\n    display:flex;\n    border: 2px solid var(--dark-gray1);\n}\n\n.miniprojects-navbar a {\n    display:flex;\n    justify-content: center;\n    align-items: center;\n    padding: 5px 20px 5px 20px;\n    text-align:center;\n    width: 10%;\n    text-decoration:none;\n    color:black;\n}\n\n.miniprojects-navbar a:first-child{\n    margin-right:auto;\n}\n\n.miniprojects-navbar a.active{\n    background-color:var(--blue1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
